export default {
  applyName: [
    {
      required: true,
      message: "请输入姓名",
    },
    {
      max: 100,
      message: "最多100字",
    },
  ],
  idCardType: [
    {
      required: true,
      message: "请选择证件类型",
    },
  ],
  phone: [
    {
      required: true,
      message: "请输入手机号",
    },
    {
      pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
      message: "请检查手机号格式",
    },
  ],
  highTalentType: [
    {
      required: true,
      message: "请输入高层次人才类别",
    },
    {
      max: 100,
      message: "最多100字",
    },
  ],
  applyIdentify: [
    {
      required: true,
      message: "请选择申请人身份",
    },
    {
      max: 100,
      message: "最多100字",
    },
  ],

  childrenName: [
    {
      required: true,
      message: "请输入子女姓名",
    },
    {
      max: 100,
      message: "最多100字",
    },
  ],
  childrenGender: [
    {
      required: true,
      message: "请选择性别",
    },
  ],
  childrenNation: [
    {
      required: true,
      message: "请选择民族",
    },
  ],
  childrenbirth: [
    {
      required: true,
      message: "请选择出生年月日",
    },
  ],

  childrenIdCard: [
    {
      required: true,
      message: "请输入子女身份证号",
    },
    {
      pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
      message: "子女身份证号格式不正确",
    }
  ],
  applySchool: [
    {
      required: true,
      message: "请选择意愿就读学校",
    },
    {
      max: 100,
      message: "最多100字",
    },
  ],
  oldSchool: [
    {
      required: true,
      message: "请输入原就读学校名称",
    },
    {
      max: 100,
      message: "最多100字",
    },
  ],
  oldSchoolArea: [
    {
      required: true,
      message: "请选择原就读学校所在区",
    },
  ],

  domicile: [
    {
      required: true,
      message: "请输入户籍所在地",
    },
  ],
  domicilePolice: [
    {
      required: true,
      message: "请输入户籍所在地派出所",
    },
    {
      max: 100,
      message: "最多100字",
    },
  ],
  homeArea: [
    {
      required: true,
      message: "请输入家庭所在辖区",
    },
  ],
  homeAreaXiang: [
    {
      required: true,
      message: "请输入家庭所在辖区（办事处/乡）",
    },
    {
      max: 100,
      message: "最多100字",
    },
  ],
  homeAreaCun: [
    {
      required: true,
      message: "请输入家庭所在辖区（社区居/村委会）",
    },
    {
      max: 100,
      message: "最多100字",
    },
  ],
  community: [
    {
      required: true,
      message: "请输入住址所在小区名称",
    },
    {
      max: 100,
      message: "最多100字",
    },
  ],
  famliyArea: [
    {
      required: true,
      message: "请选择市内家庭详细地址所在区",
    },
  ],
  famliyAreaDetail: [
    {
      required: true,
      message: "请输入市内家庭详细地址",
    },
    {
      max: 100,
      message: "最多100字",
    },
  ],
  schoolHistory: {
    type: 'array',
    require: true,
    defaultField: {
      type: 'object',
      fields: {
        schoolType: {
          required: true,
          message: "请选择学习阶段",
        },
        timeRange: [
          {
            required: true,
            type: 'array',
            message: "请选择学习时间范围",
          },
        ],
        schoolName: [
          {
            required: true,
            message: "请输入学校名称",
          },
          {
            max: 100,
            message: "最多100字",
          },
        ],
      }
    },
  },
  family: {
    type: 'array',
    required: true,
    defaultField: {
      type: 'object',
      fields: {
        familyTies: [
          {
            required: true,
            message: "请选择家庭成员关系",
          },
        ], //关系
        familyName: [
          {
            required: true,
            message: "请输入家庭成员姓名",
          },
          {
            max: 100,
            message: "最多100字",
          },
        ], //姓名
        famIdCardType: [
          {
            required: true,
            message: "请输入家庭成员证件类型",
          },
        ],
        famIdCardNum: [
          {
            required: true,
            message: "请输入家庭成员身份证号",
          },
          {
            pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
            message: "家庭成员身份证号格式不正确",
          }
        ],
        workCmpy: [
          {
            max: 100,
            message: "最多100字",
          },
        ],
        phone: [
          {
            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
            message: "家庭成员手机号格式不正确",
          },
        ],
      }

    },
  },
}