<template>
  <!--
  *@des: 高层次人才子女入学
  -->
  <section class="form-wrapper">
    <div>
      <div class="notices">
        <p class="notice-item">填写说明：</p>
        <p class="notice-item">
          1、“市内家庭详细住址”一栏，应注明家庭所在路、街、院、排、楼号、楼门号及门牌号，非本地户籍请填写居住证地址且居住证地址需为真实有效地址。
        </p>
        <p class="notice-item">
          2、填表后请确认信息真实准确无误，如出现虚假信息取消入学资格。
        </p>
      </div>
      <van-form ref="form" class="form">
        <div class="tip">
          <van-notice-bar
            color="#1989fa"
            background="#ecf9ff"
            text="申请人信息"
          />
        </div>

        <van-field
          v-model="form.applyName"
          name="applyName"
          label="姓名"
          placeholder="请输入姓名"
          required
        />
        <van-field-select
          v-model="form.idCardType"
          :options="idCardTypes"
          :fieldProps="{
            label: '证件类型',
            required: true,
            placeholder: '请选择证件类型',
          }"
        ></van-field-select>
        <div style="display: flex">
          <van-field
            v-model="form.idCardNum"
            name="idCardNum"
            label="证件号码"
            placeholder="请输入证件号码"
            required
          />
          <van-button class="idCardNum-button" @click="gccrcsbhy"
            >查询证书</van-button
          >
        </div>
        <van-field
          v-model="form.phone"
          name="phone"
          label="手机号"
          placeholder="请输入手机号"
          required
          maxlength="11"
        />
        <van-field-select
          v-model="form.applyIdentify"
          :options="applyPeopleTypes"
          :fieldProps="{
            label: '申请人身份',
            required: true,
            placeholder: '请选择申请人身份',
          }"
        ></van-field-select>
        <van-field
          v-model="form.highTalentType"
          label="高层次人才类别"
          placeholder="请输入高层次人才类别"
          required
          :disabled="hasHighTalent"
        />
        <!-- 入学子女信息 -->
        <div class="tip">
          <van-notice-bar
            color="#1989fa"
            background="#ecf9ff"
            text="入学子女信息"
          />
        </div>
        <van-field
          v-model="form.childrenName"
          label="姓名"
          placeholder="请输入入学子女姓名"
          required
        />
        <van-field-select
          v-model="form.childrenGender"
          :options="sexTypes"
          :fieldProps="{
            label: '性别',
            required: true,
            placeholder: '请选择入学子女性别',
          }"
        ></van-field-select>
        <van-field-select
          v-model="form.childrenNation"
          :options="nations"
          :fieldProps="{
            label: '民族',
            required: true,
            placeholder: '请选择民族',
          }"
        ></van-field-select>
        <van-field-calendar
          v-model="form.childrenbirth"
          :calendarProps="{
            mode: 'single',
          }"
          :fieldProps="{
            required: true,
            label: '出生年月日',
            placeholder: '请输入出生年月日',
          }"
          required
        />
        <van-field
          v-model="form.childrenIdCard"
          label="身份证号"
          placeholder="请输入身份证号"
          required
        />
        <van-field-select
          v-model="form.applySchool"
          :options="schoolList"
          :fieldProps="{
            label: '意愿学校',
            required: true,
            placeholder: '请选择意愿学校',
          }"
        ></van-field-select>
        <van-field-area
          v-model="form.oldSchoolArea"
          :fieldProps="{
            required: true,
            label: '原就读学校所在区',
            placeholder: '请选择原就读学校所在区',
          }"
        />
        <van-field
          v-model="form.oldSchool"
          label="原就读学校名称"
          placeholder="请输入原就读学校名称"
          required
        />
        <van-field-area
          v-model="form.domicile"
          :fieldProps="{
            required: true,
            label: '户籍所在地',
            placeholder: '户籍所在地',
          }"
        />
        <van-field
          v-model="form.domicilePolice"
          label="户籍派出所"
          placeholder="请输入户籍派出所"
          required
        />
        <van-field-area
          v-model="form.homeArea"
          :fieldProps="{
            required: true,
            label: '家庭所在辖区',
            placeholder: '请输入家庭所在辖区',
          }"
        />
        <van-field
          v-model="form.homeAreaXiang"
          label="辖区办事处（乡）"
          placeholder="请输入辖区办事处（乡）"
          required
        />
        <van-field
          v-model="form.homeAreaCun"
          label="社区居（村）委会"
          placeholder="请输入社区居（村）委会"
          required
        />
        <van-field
          v-model="form.community"
          label="住址所在小区名称"
          placeholder="请输入住址所在小区名称"
          required
        />
        <van-field-area
          v-model="form.famliyArea"
          :fieldProps="{
            required: true,
            label: '市内家庭所在区',
            placeholder: '请选择市内家庭所在区',
          }"
        />
        <van-field
          v-model="form.famliyAreaDetail"
          label="市内家庭详细住址"
          placeholder="请输入市内家庭详细住址"
          required
        />
        <!-- 入学子女简历 -->
        <div class="tip">
          <van-notice-bar
            color="#1989fa"
            background="#ecf9ff"
            text="入学子女简历"
          />
        </div>
        <div>
          <div v-for="(item, i) in form.schoolHistory" :key="i">
            <van-field-select
              v-model="item.schoolType"
              :options="schoolTypes"
              :fieldProps="{
                label: '学习阶段',
                required: true,
                placeholder: '请选择学习阶段',
              }"
            ></van-field-select>
            <van-field-calendar
              v-model="item.timeRange"
              :calendarProps="{
                mode: 'during',
              }"
              :fieldProps="{
                required: true,
                label: '学习起止时间',
                placeholder: '请输入出生年月日',
              }"
              required
            />
            <van-field
              v-model="item.schoolName"
              label="学习学校"
              placeholder="请输入学习学校"
              :required="true"
              maxlength="100"
            />
            <p class="fam-btns">
              <van-button
                class="btn"
                type="default"
                size="mini"
                @click="onAddSchool()"
                >新增
              </van-button>
              <van-button
                class="btn"
                type="danger"
                size="mini"
                @click="onDeleteSchool(i)"
                >删除
              </van-button>
            </p>
          </div>
        </div>

        <!-- 家庭关系 -->
        <div class="tip">
          <van-notice-bar
            color="#1989fa"
            background="#ecf9ff"
            text="入学子女家庭成员"
          />
        </div>
        <div>
          <div v-for="(item, i) in form.family" :key="i">
            <van-field-select
              v-model="item.familyTies"
              :options="familyTies"
              :fieldProps="{
                label: '关系',
                required: true,
                placeholder: '请选择关系',
              }"
            ></van-field-select>
            <van-field
              v-model="item.familyName"
              name="familyName"
              label="姓名"
              placeholder="请输入姓名"
              :required="true"
              maxlength="100"
            />
            <van-field-select
              v-model="item.famIdCardType"
              :options="idCardTypes"
              :fieldProps="{
                label: '证件类型',
                required: true,
                placeholder: '请选择证件类型',
              }"
            ></van-field-select>
            <van-field
              v-model="item.famIdCardNum"
              name="famIdCardNum"
              label="身份证号"
              placeholder="请输入身份证号"
              :required="true"
              maxlength="100"
            />
            <van-field
              v-model="item.workCmpy"
              label="工作单位"
              placeholder="请输入工作单位"
              maxlength="100"
            />
            <van-field
              v-model="item.phone"
              label="联系方式"
              placeholder="请输入联系方式"
              maxlength="100"
            />
            <p class="fam-btns">
              <van-button
                class="btn"
                type="default"
                size="mini"
                @click="onAddFamily"
              >
                新增
              </van-button>
              <van-button
                class="btn"
                type="danger"
                size="mini"
                @click="onDeleteFamily(i)"
              >
                删除
              </van-button>
            </p>
          </div>
        </div>
      </van-form>
    </div>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
import { gccrcsbhy } from "@/api/policy/apply";
import { nations } from "../config";
import formRules from "./rules";
export default {
  name: "hight-house-subsidy",
  mixins: [formMixin],
  data() {
    return {
      nations,
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        phone: "",
        idCardNum: "", //证件号码
        highTalentType: "", //人才类别
        applyIdentify: "", //申请人身份
        childrenName: "", //姓名
        childrenGender: "", //姓别
        childrenNation: "", //民族
        childrenbirth: "", //出生年月日
        childrenIdCard: "", //身份证号
        applySchool: "", //意愿就读学校名称
        oldSchool: "",
        oldSchoolArea: [],
        domicile: [], //户籍所在地
        domicilePolice: "", //户籍所在地派出所
        homeArea: [], //家庭所在辖区
        homeAreaXiang: "", //家庭所在辖区 乡
        homeAreaCun: "", //家庭所在辖区 村
        community: "", //住址所在小区名称
        famliyArea: [], //市内家庭所在区
        famliyAreaDetail: "", //市内家庭详细地址
        family: [
          {
            familyTies: "", //关系
            familyName: "", //姓名
            famIdCardType: "居民身份证",
            famIdCardNum: "",
            workCmpy: "",
            phone: "",
          },
        ],
        schoolHistory: [
          {
            schoolType: "", //学习阶段
            timeRange: "", //起止时间
            schoolName: "", //学校名称
          },
        ],
      },
      formRules: formRules,
      formValidator: null,
      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证",
        },        
        {
          value: "护照",
          label: "护照",
        },
      ],
      schoolList: [{ value: "护航路小学", label: "护航路小学" }],
      schoolTypes: [
        { value: "幼儿园", label: "幼儿园" },
        { value: "小学", label: "小学" },
        { value: "中学", label: "中学" },
      ],
      //申请人身份
      applyPeopleTypes: [
        { value: "父亲", label: "父亲" },
        { value: "母亲", label: "母亲" },
        { value: "监护人", label: "监护人" },
      ],
      sexTypes: [
        { value: "男", label: "男" },
        { value: "女", label: "女" },
      ],
      //家庭关系
      familyTies: [
        {
          value: "配偶",
          label: "配偶",
        },
        {
          value: "儿子(未成年)",
          label: "儿子(未成年)",
        },
        {
          label: "女儿(未成年)",
          value: "女儿(未成年)",
        },
      ],
      hasHighTalent: true,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.global.userInfo;
    },
  },

  created() {},
  methods: {
    gccrcsbhy() {
      if (
        !this.form.applyName ||
        !this.form.idCardNum ||
        !this.form.idCardType
      ) {
        this.$toast("用户名与证件号码不能为空");
        return;
      }
      const idCardRule =
        /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
      if (
        "居民身份证" == this.form.idCardType &&
        !idCardRule.test(this.form.idCardNum)
      ) {
        this.$toast("证件号格式不正确");
        return;
      }
      gccrcsbhy({
        perCard: this.form.idCardNum,
        perName: this.form.applyName,
        type: "居民身份证" == this.form.idCardType ? "1" : "0",
      })
        .then((res) => {
          let { success, data } = res;
          if (success) {
            this.form.highTalentType = data[0].endCertificateName;
          } else {
            this.$toast("暂无高层次人才认证,请手动输入高层次人才类别");
            this.hasHighTalent = false;
          }
        })
        .catch((e) => {
          this.$toast("暂无高层次人才认证,请手动输入高层次人才类别");
          this.hasHighTalent = false;
        });
    },
    /*
     *@des: 新增家庭成员
     */
    onAddFamily() {
      this.form.family.push({
        familyTies: "",
        familyName: "",
        famIdCardType: "居民身份证",
        famIdCardNum: "",
        famDomicile: [],
      });
    },
    /*
     *@des: 删除家庭成员
     */
    onDeleteFamily(index) {
      if (this.form.family.length === 1) {
        this.$toast("最后一条不能删除");
        return;
      }
      this.form.family.splice(index, 1);
    },
    /*
     *@des: 新增家庭成员
     */
    onAddSchool() {
      this.form.schoolHistory.push({
        schoolType: "", //学习阶段
        timeRange: "", //起止时间
        schoolName: "", //学校名称
      });
    },
    /*
     *@des: 删除家庭成员
     */
    onDeleteSchool(index) {
      if (this.form.schoolHistory.length === 1) {
        this.$toast("最后一条不能删除");
        return;
      }
      this.form.schoolHistory.splice(index, 1);
    },
    /*
     *@des: 初始化校验
     */
    initFormValidator() {
      const idCardRule = [
        {
          required: true,
          message: "请输入证件号",
          trigger: "blur",
        },
        {
          pattern:
            /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
          message: "证件号格式不正确",
          trigger: "blur",
        },
      ];
      const passPortRule = {
        required: true,
        message: "请输入证件号",
        trigger: "blur",
      };
      if ("居民身份证" == this.form.idCardType) {
        this.formRules.idCardNum = idCardRule;
      } else {
        this.formRules.idCardNum = passPortRule;
      }
      this.formValidator = new Schema(this.formRules);
    },
    /*
     *@des: 校验表单
     */
    validate() {
      this.initFormValidator();
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(() => {
            resolve({
              ...this.form,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  margin-top: 0.2rem;
  background: #fff;
  .title-gutter {
    width: fit-content;
    margin-left: 0.3rem;
    padding: 0.3rem 0.1rem 0rem 0rem;
    font-size: 0.26rem;
    line-height: 0.26rem;
    border-bottom: 2px solid #3c37d2;
  }
  .fam-btns {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #eee;
    .btn {
      width: 1.5rem;
      margin-right: 0.15rem;
    }
  }
}
.form {
  overflow: auto;
}
.tip {
  margin: 0.2rem;
}
.idCardNum-button {
  width: 2rem;
  padding: 0 0.15rem;
}
</style>
